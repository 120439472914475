import { makeAutoObservable, reaction } from "mobx";

import { Store } from "./Store";
import { ApiClient, GraphqlClient } from "~libs/api";
import { ISingleWordpressArticle } from "~models/Article";
import { ISingleWordpressDocument } from "~models/Document";
import { IFormSelect, formDataToApiData, IFormData } from "~models/FormData";

export class LibraryStore {
  private open: boolean;

  document: ISingleWordpressDocument | ISingleWordpressArticle;

  requestStatus: "idle" | "pending" = "idle";

  createCollectionformData = {
    title: {
      value: "",
      method: value => !!value,
      valid: null
    },
    description: {
      value: "",
      method: value => !!value,
      valid: null
    }
  };

  collections = [] as IFormSelect[];

  createCollection = false;

  get isOpen() {
    return this.open;
  }

  get isUploadValid() {
    if (this.createCollection) {
      return !Object.keys(this.createCollectionformData || {}).find(key => !this.createCollectionformData[key].valid);
    }

    return this.collections.length;
  }

  constructor(private store: Store, private client: ApiClient, private graphqlClient: GraphqlClient) {
    makeAutoObservable(this);

    reaction(
      () => this.store.userStore.currentUser,
      () => {
        if (typeof this.store.userStore.currentUser === "object" && Object.keys(this.store.userStore.currentUser).length) {
          this.store.userStore.fetchUserCollections();
          this.store.userStore.getUserLibrary();
        }
      }
    );
  }

  onOpenModal = (document: ISingleWordpressDocument | ISingleWordpressArticle) => {
    this.document = document;
    this.reset();
    this.open = true;
  };

  onCloseUpdateModal = () => {
    this.open = false;
  };

  reset = () => {
    this.collections = this.store.userStore.userCollections.map(collection => ({
      id: collection.databaseId,
      title: collection?.userCollectionFields?.title,
      selected: false
    }));

    this.createCollectionformData.title.value = "";
    this.createCollectionformData.description.value = "";
  };

  selectCollection = selectCollection => {
    this.collections = this.store.userStore.userCollections.map(collection => ({
      id: collection.databaseId,
      title: collection.userCollectionFields.title,
      selected: collection.databaseId === selectCollection.id
    }));

    this.createCollectionformData.title.value = "";
    this.createCollectionformData.description.value = "";
    this.createCollection = !selectCollection.id ? selectCollection : false;
  };

  onAddToLibrary = async () => {
    if (this.requestStatus == "pending") {
      return;
    }
    this.requestStatus = "pending";

    let promises = this.collections.filter(collection => collection.selected).map(collection => this.client.addPostToUserCollection(collection.id, this.document));

    if (!promises.length && !this.createCollection) {
      if (this.store.userStore.myLibrary === undefined) {
        this.createCollectionformData.title.value = "default";
        const data: any = formDataToApiData(this.createCollectionformData);
        data.collection_posts = [this.document.id];
        promises.push(this.client.createUserCollection(data));
      } else {
        promises = [this.store.userStore.addPostToUserLibrary(this.document.databaseId)];
      }
    }
    if (this.createCollection) {
      const data: any = formDataToApiData(this.createCollectionformData);
      data.collection_posts = [this.document.id];

      await promises.push(this.client.createUserCollection(data));
    }

    await Promise.all(promises);
    this.onCloseUpdateModal();
    this.requestStatus = "idle";
    this.store.userStore.fetchUserCollections();
  };

  setAndValidate = (data: IFormData<any>, value: any) => {
    data.value = value;
    this.validate(data);
  };

  validate = (data: IFormData<any>) => {
    data.valid = data.method(data.value);
  };

  documentInUserCollection = (documentId: number) => {
    if (!documentId) return false;
    const postIds = this.store.userStore?.userCollections
      ?.concat(this.store.userStore.myLibrary)
      ?.map(col => col?.userCollectionFields?.collectionPosts?.map(post => post.databaseId))
      ?.flat();
    if (postIds.some(id => id === documentId)) {
      return true;
    }
    return false;
  };
}
