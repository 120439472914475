import { GDocument, GDocumentListView } from "./document";

export const GCollectionModalView = `
  id
  databaseId
  title
  nodeType: __typename
`;

export const GCollectionListView = `
  id
  databaseId
  title
  nodeType: __typename
  collectionThumbnail: featuredImage {
    image: node {
      mediaItemUrl
      altText
      title
    }
  }
  collectionFields {
    documents {
      ... on Document {
        ${GDocumentListView}
      }
    }
  }
`;

export const GCollection = `
id
nodeType: __typename
databaseId
slug
title
link
collectionThumbnail: featuredImage {
  image: node {
    mediaItemUrl
    altText
    title
  }
}
collectionFields {
  title
  description
  documents {
    ... on Document {
      ${GDocument}
    }
  }
}
`;
