exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-[lang]-internal-document-[slug]-tsx": () => import("./../../../src/pages/[lang]/internal_document/[slug].tsx" /* webpackChunkName: "component---src-pages-[lang]-internal-document-[slug]-tsx" */),
  "component---src-pages-[lang]-internal-pdf-[slug]-tsx": () => import("./../../../src/pages/[lang]/internal_pdf/[slug].tsx" /* webpackChunkName: "component---src-pages-[lang]-internal-pdf-[slug]-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-api-docs-tsx": () => import("./../../../src/pages/api/docs.tsx" /* webpackChunkName: "component---src-pages-api-docs-tsx" */),
  "component---src-pages-data-dashboard-tsx": () => import("./../../../src/pages/data-dashboard.tsx" /* webpackChunkName: "component---src-pages-data-dashboard-tsx" */),
  "component---src-pages-evaluation-hub-tsx": () => import("./../../../src/pages/evaluation-hub.tsx" /* webpackChunkName: "component---src-pages-evaluation-hub-tsx" */),
  "component---src-pages-internal-collections-[id]-tsx": () => import("./../../../src/pages/internal_collections/[id].tsx" /* webpackChunkName: "component---src-pages-internal-collections-[id]-tsx" */),
  "component---src-pages-internal-document-[slug]-tsx": () => import("./../../../src/pages/internal_document/[slug].tsx" /* webpackChunkName: "component---src-pages-internal-document-[slug]-tsx" */),
  "component---src-pages-internal-pdf-[slug]-tsx": () => import("./../../../src/pages/internal_pdf/[slug].tsx" /* webpackChunkName: "component---src-pages-internal-pdf-[slug]-tsx" */),
  "component---src-pages-user-collections-[id]-tsx": () => import("./../../../src/pages/user_collections/[id].tsx" /* webpackChunkName: "component---src-pages-user-collections-[id]-tsx" */),
  "component---src-views-pages-article-tsx": () => import("./../../../src/views/pages/Article.tsx" /* webpackChunkName: "component---src-views-pages-article-tsx" */),
  "component---src-views-pages-collection-tsx": () => import("./../../../src/views/pages/Collection.tsx" /* webpackChunkName: "component---src-views-pages-collection-tsx" */),
  "component---src-views-pages-document-tsx": () => import("./../../../src/views/pages/Document.tsx" /* webpackChunkName: "component---src-views-pages-document-tsx" */),
  "component---src-views-pages-page-tsx": () => import("./../../../src/views/pages/Page.tsx" /* webpackChunkName: "component---src-views-pages-page-tsx" */),
  "component---src-views-pages-pdf-tsx": () => import("./../../../src/views/pages/PDF.tsx" /* webpackChunkName: "component---src-views-pages-pdf-tsx" */),
  "component---src-views-pages-search-tsx": () => import("./../../../src/views/pages/Search.tsx" /* webpackChunkName: "component---src-views-pages-search-tsx" */),
  "component---src-views-pages-taxonomy-tsx": () => import("./../../../src/views/pages/Taxonomy.tsx" /* webpackChunkName: "component---src-views-pages-taxonomy-tsx" */),
  "component---src-views-pages-term-tsx": () => import("./../../../src/views/pages/Term.tsx" /* webpackChunkName: "component---src-views-pages-term-tsx" */),
  "component---src-views-pages-toolkit-tsx": () => import("./../../../src/views/pages/Toolkit.tsx" /* webpackChunkName: "component---src-views-pages-toolkit-tsx" */),
  "component---src-views-pages-translated-document-tsx": () => import("./../../../src/views/pages/TranslatedDocument.tsx" /* webpackChunkName: "component---src-views-pages-translated-document-tsx" */)
}

