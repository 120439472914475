export default {
  translation: {
    header: {
      menu: "Menu",
      myAccount: "My Account",
      menuTitle: "EXPLORE THE LIBRARY",
      welcome: "Welcome",
      accountSettings: "Account settings",
      discovery: "Discovery Feed",
      evaluationHub: "Evaluation Hub",
      collections: "My collections"
    },
    search: {
      separateWordsWithComma: "Separate words or phrases with a comma",
      allOfTheseWords: "All of these words",
      advancedSearch: "Advanced search",
      thisExactPhrase: "This exact phrase",
      narrowDownWithAdvancedSearch: "Narrow it down with Advanced search",
      titleOrAbstract: "Title or abstract",
      advancedSearchSummary: "Advanced search summary",
      anyFieldContainsAllWords: "Any field contains all of the words",
      atLastOneOfTheWords: "With at least one of the words",
      noneOfTheFieldsContain: "None of the fields contain",
      noneOfTheseWords: "None of these words",
      editAdvancedSearch: "Edit advanced search",
      andIsPublished: "And is published",
      returnArticlesDatedBetween: "Return articles dated between",
      summary: "Summary",
      enterSearchQuery: "Enter search query",
      needToNarrowItDownMore: "Need to narrow it down even more?",
      displayingResults: "Displaying {{displaying}} of {{totalAmount}} results",
      countResults: "{{count}} results",
      noResultsFound: "No results found",
      suggestedTerms: "Check out some of these topics:",
      noResults: "No results",
      topSearches: "Top searches:",
      allCountries: "All countries",
      addCountriesToQuery: "add countries to your search query",
      addToQuery: "Add to search query",
      withinTheYears: "Within the years",
      weFound: "We found",
      no: "No",
      document: "Document",
      documents: "Documents",
      title: "Title",
      abstract: "Abstract",
      text: "Text",
      exportExcel: "Export list of search results",
      tooLargeForExport: "Current result is too large for exporting. Please narrow down your result.",
      searching: "Searching..."
    },
    filter: {
      sortBy: "Sort by",
      relevance: "Relevance",
      alphabetical: "Alphabetical",
      latest: "Latest",
      prioritize: "Prioritize",
      publicationYear: "Publication year",
      publicationYears: "Publication years",
      topics: "Topics",
      authors: "Authors",
      contributors: "Contributors",
      publisher: "Publisher",
      publishers: "Publishers",
      contentType: "Content type",
      country: "Country",
      region: "Region",
      language: "Language",
      format: "Format",
      keywords: "Keywords",
      browseAuthors: "Browse authors",
      browseKeywords: "Browse keywords",
      rights: "Rights",
      license: "License",
      refineResults: "Refine results",
      applyFilter: "Apply Filter",
      resetFilter: "Reset Filter",
      filterAndSort: "Filter And Sort",
      filterAndSortThroughCollection: "Filter And Sort Through Collection",
      internalSearch: "Internal",
      publicSearch: "Public",
      staffFirst: "Staff only first"
    },
    collection: {
      subscribeToCollection: "Subscribe to this collection",
      aboutCollection: "About the collection",
      popularCollectionTags: "Popular Collection tags",
      numberOfResourcesInCollection: "{{numberOfResources}} resources in this collection",
      imageCaption: "Caption: {{caption}}",
      contributors: "Contributors to this collection",
      grantOwnership: "Grant ownership of collection",
      removeContributor: "Remove contributor",
      contributor: "Contributor",
      author: "Author",
      collectionNotFound: "Could not find collection"
    },
    resourceDropdown: {
      resourceCount: "{{count}} resource",
      resourceCount_0: "{{count}} resources",
      resourceCountPlural: "{{count}} resources"
    },
    banner: {
      subscribeAndReceive: "Subscribe and receive reading selections",
      saveAllYourFavouriteMaterials: "Save all your favorite materials for future use",
      uploadResearch: "Upload research & contribute to the collection"
    },
    topicInformation: {
      subscribeToTopic: "Subscribe to this topic",
      aboutTheTopic: "About the topic"
    },
    navigation: {
      youAreHere: "You are here:",
      viewMoreItems: "View {{number}} more items",
      myLibrary: "My library",
      numberOfResources: "{{number}} resources"
    },
    staff: {
      userPreferenceHeading: "FINE TUNE WHAT APPEARS ON YOUR DISCOVERY FEED",
      nextStep: "Next step: {{currentStep}} of {{totalSteps}}",
      step: "Step {{currentStep}} of {{totalSteps}}",
      configureLater: "Do this later",
      configureInAccount: "You can configure this later in your account settings",
      toggleAll: "Toggle all",
      countriesDescription: "What countries are you interested in?",
      topicsDescription: "What topics are you interested in?",
      configurePreferences: "These recommendations are based on your topics of interest, your country and area of expertise. Go to your Account settings to finetune and customize what appears on your discovery feed and recommendations",
      spotlight: "In the spotlight",
      yourDiscoveryFeed: "Your Discovery Feed",
      recommendedForYou: "Recommended For You",
      recommendedDocuments: "Recommended Documents",
      recommendedCollections: "Recommended Collections",
      managePreferences: "Discovery Feed Settings",
      selectAll: "Select all",
      welcomeText: "Welcome to the new staff edition of the Resource Centre. This space is unique to staff. <br /><br /> You can set your preferences, creating a tailored experience in the all-new Discovery Feed, and search staff-only materials alongside public materials. <br /><br /> Celebrate your love of learning: explore the site, create new collaborative collections, and share with your colleagues!",
      startDiscovering: "Set preferences and start discovering!"
    },
    authentication: {
      signIn: "Sign in",
      loginRegister: "Login / Register",
      loginHere: "Login here",
      toYourAccount: "to your account",
      logOut: "Log out",
      login: "Log in",
      dontHaveAnAccount: "Don't have an account?",
      alreadyHaveAnAccount: "Already have an account?",
      register: "Register",
      registerNow: "Register Now",
      registerAnAccount: "Register an account",
      forgotPassword: "Forgot your password?",
      changePassword: "Change password",
      passwordDescription: "Your password has to be at least 6 characters long. Must contain at least one lower case letter, one upper case letter, one digit.",
      imNotARobot: "I'm not a robot",
      editAccount: "Edit Account",
      staffLogin: "Save the Children staff login",
      registrationComplete: "Registration complete. Redirecting to login shortly.",
      pleaseWait: "Logging you in. Please wait"
    },
    document: {
      viewFullAbstract: "View full abstract",
      readFullAbstract: "Read full abstract",
      peerReviewed: "Peer reviewed",
      recommended: "Recommended",
      subscribeAndRecieveReadingSelections: "Subscribe and receive reading selections",
      saveAllYourFavouriteMaterials: "Save all your favorite materials for future use",
      uploadResearch: "Upload research & contribute to the collection",
      relatedDocuments: "Related Documents",
      moreAuthors: "{{number}} more authors",
      numberOfPages: "Number of pages: {{numberOfPages}}",
      documentInformation: "Document information",
      documentsDropdownCount: "{{number}} Documents",
      showingNumberOfPosts: "Showing {{visible}} of {{total}}",
      document: "Document",
      printDocument: "Print document",
      pages: "pages",
      viewAbstract: "View Full Abstract",
      trackingFormHeading: "Thank you for your interest in {{name}}!",
      trackingFormBody: "Would you be willing to help us improve our resources? If so, would you consider sharing your information so we can stay in touch?",
      trackingFormSkip: "Skip this step",
      trackingFormDisclaimer: "We will not share your information with any third parties."
    },
    dateRangeDropdown: {
      customDateRange: "Custom date range",
      lowerRangeLabel: "From:",
      upperRangeLabel: "To:"
    },
    input: {
      email: "E-mail",
      password: "Password",
      repeatPassword: "Repeat Password",
      username: "Username",
      searchAuthors: "Search authors",
      searchQuestions: "Search questions",
      searchCatalogue: "Search catalogue",
      searchKeywords: "Search keywords",
      firstAndLastName: "First and last name",
      firstName: "First name",
      lastName: "Last name",
      required: "Required"
    },
    buttons: {
      viewAndDownload: "View & Download",
      view: "View",
      referenceGenerator: "Reference generator",
      updateSettings: "Update settings",
      deleteMyAccount: "Delete my account"
    },
    languages: {
      language: "Language",
      selectedLanguage: "Selected language",
      autodetectedLanguage: "Autodetected language",
      defaultLanguage: "Default language",
      otherLanguages: "Other languages",
      selectPreferredLanguage: "Select your preferred language",
      updateLanguagePreference: "Update language preference"
    },
    saveToLibrary: {
      saveToLibrary: "Save to library"
    },
    paginator: {
      pageOf: "Page {{currentPage}} of {{numberOfPages}}",
      jumpToPage: "Jump to page:"
    },
    profile: {
      editProfile: "Edit profile",
      viewAll: "View all",
      numberOfCollections: "{{ number }} collections",
      numberOfResources: "{{ number }} resources",
      myLibrary: "My library",
      collections: "Collections",
      savedResources: "Saved resources",
      numberOfSavedResources: "{{ number }} saved resources",
      emptyLibrary: "You haven't added any posts to your library yet.",
      addingToLibrary: "Adding post to library...",
      emptyCollection: "You haven’t added any materials to this collection yet",
      noMaterialYet: "Oops, no materials here! Please add some"
    },
    internalCollection: {
      collaborators: "Collaborators",
      emailAddresses: "Email addresses",
      sendInvitations: "Send invitations",
      addCollaborators: "Add collaborators",
      pendingCollaborators: "Pending collaborators",
      placeholder: "name@savethechildren.org",
      inviteMore: "Invite more collection contributors",
      addMoreCollaborators: "Add more collection contributors",
      downloadResources: "Download all resources (.zip)",
      successfullyCopied: "Copied to clipboard",
      editTitleName: "Name of collection",
      editTitleText: "Give it a unique name with fewer than 60 characters",
      editDescription: "Tell others what this collection is all about",
      editPrivateName: "Keep this collection private",
      editPrivateText: "This collection will not show up in search results and will not show up in other staff members discovery feeds",
      deleteCollection: "Delete collection",
      confirmation: "Are you sure?",
      staffOnly: "Staff only",
      curatedBy: "Curated by {{ curators }}",
      showMoreCount: "+{{ count }} more"
    },
    UserCollectionPage: {
      numberOfResources: "{{ count }} resources in this collection",
      popularCollectionTags: "Popular Collection tags:",
      visiblePosts: "Showing {{ visiblePosts }} of {{ numberOfPosts }}"
    },
    EditUserCollectionModal: {
      uploadFailed: "Something went wrong",
      collectionTitle: "Collection title",
      description: "Description",
      saveButton: "Save changes"
    },
    AddDocumentToLibraryModal: {
      createCollection: "Add to new collection",
      addToLibrary: "Add to my library",
      addToCollection: "Add to collection"
    },
    UploadDocumentsModal: {
      uploadPending: "Please wait while your resources are being uploaded",
      uploadSuccess: "Great! Your resources have been successfully uploaded",
      errorTitleUsed: "{{ title }} is already being used.",
      errorGeneric: "Something went wrong.",
      title: "Upload research & contribute to the collection",
      description: "Use the form below to upload / share the document on the Resource Centre.",
      cataloguerWillReview: "Before the document will be published the Resource Centre Cataloguer will review it.",
      filesDescription: "Files must be less than 500 MB. Allowed file types: {{- filetypes }}.",
      publicPolicy: `
<ul>
  <li>The document must be linked to one of Save the Children's global themes (child protection, child rights governance, child poverty, health and nutrition, education and cross thematic areas)</li>
  <li>In its final version</li>
  <li>Intended for public consumption</li>
  <li>Approved for circulation by the copyright holder</li>
</ul>
`,
      internalPolicy: `
<ul>
  <li>The document must be linked to one of Save the Children's global themes (child protection, child rights governance, child poverty, health and nutrition, education and cross thematic areas)</li>
  <li>In its final version</li>
  <li>Intended for internal consumption</li>
</ul>
      `,
      approvedPolicy: "I have read and approved the Upload Policy",
      submit: "Submit document",
      uploadContent: "Upload Content",
      externalUse: "External Use",
      externalUseApproved: "Has the document been authorized for external use?",
      externalDescription: "Should the document be accessible by the general public, or is limited to Save the Children staff members. If in doubt, leave this unchecked.",
      abstractGuidance: `
      <p>An abstract is generally no longer than a paragraph; it is intended to capture and convey the overall message of the material. We recommend that the abstract include a “hook” or a problem statement (why the material was produced). You may also consider including information on the following: </p>
      <ul style="margin-top: 1rem;">
        <li>Context and topic</li>
        <li>Main findings</li>
        <li>Significance of those findings</li>
      </ul>
      <p style="margin-bottom: 1rem;">Please remember that search engines index abstracts (as well as title) to identify key terms. What you include in the abstract will support in its discovery.</p>

      <p>Copilot (Bing chat) is the approved AI tool for Save the Children staff. If you do use AI tools, we strongly recommend that you proofread the results for accuracy and relevance.</p>`,
      guidanceToggle: "What makes a good abstract?"
    },
    Filepicker: {
      dropFilesHere: "Drop files here or",
      browse: "Browse"
    },
    filetypes: {
      "image/jpeg": "jpeg",
      "image/jpg": "jpg",
      "image/png": "png",
      "image/bmp": "bmp",
      "image/tiff": "tiff",
      "image/gif": "gif",
      "application/pdf": "pdf"
    },
    newsletter: {
      frequency: {
        daily: "Daily",
        weekly: "Weekly",
        monthly: "Monthly"
      },
      newPublications: "Let me know when we find new publications...",
      subscribe: "Subscribe",
      setupNewsletter: "Set up your newsletter subscription",
      selectInterests: "Select your interests",
      addMore: "Add more",
      onTheTopic: "...On the topic:",
      setIn: "...That's set in:",
      writtenBy: "...That's written by:",
      publishedBy: "...That's published by:",
      withContentType: "...With the content type:",
      inCollection: "...In collection:",
      someKeywords: "...That contains some of the keywords:",
      mostPopular: "Most Popular",
      allOptions: "See all customization options",
      customContent: "Completely customized content",
      notificationFrequency: "Select how often you want to be notified",
      unsubscribeAnytime: "Unsubscribe at anytime"
    },
    validators: {
      missing: "Enter {{- name, lower}}",
      number_low: "{{- name, capital}} must be at least {{value}}",
      number_high: "{{- name, capital}} may be max {{value}}",
      digit: "Your number is missing {{- name, lower}}",
      digit_plural: "There are {{count}} numbers missing in your {{- name, lower}}",
      digit_to_many: "{{count}} number too much in your {{- name, lower}}",
      digit_to_many_plural: "{{count}} too many digits in your {{- name, lower}}",
      digits_missing: "There are no numbers in your {{- name, lower}}",
      digits_to_many: "There are too many digits in your {{- name, lower}}",
      digits_wrong_char: "{{- name}} can only contain numbers",
      email: "Your specified email is not valid",
      pidn: "Your personal identification number is not valid",
      empty_field: "This field must not be left blank",
      chars_too_few: "There are too few characters in the field",
      chars_too_many: "There are too many characters in the field"
    },
    misc: {
      download: "Download",
      viewMore: "View More",
      edit: "Edit",
      share: "Share",
      startTyping: "Start typing",
      photo: "Photo: {{imageReference}}",
      copy: "Copy",
      copyLink: "Copy link",
      close: "Close",
      viewAll: "View all",
      resource: "Resource",
      resources: "Resources",
      publication: "Publication",
      publications: "Publications",
      collection: "Collection",
      collections: "Collections",
      subscriptions: "Subscriptions",
      settings: "Settings",
      showMore: "Show more",
      showLess: "Show less",
      from: "From",
      on: "On",
      to: "To",
      not: "Not",
      since: "Since",
      sinceValue: "Since {{value}}",
      anytime: "Anytime",
      done: "Done",
      search: "Search",
      clearAll: "Clear all",
      goBackTo: "Go back to",
      back: "Back",
      backToPreviousPage: "Back to previous page",
      backToStartPage: "Back to start page",
      simpleSearch: "Simple search",
      addWord: "Add word",
      addMore: "Add more",
      this: "This",
      anyField: "Any field",
      contains: "Contains",
      upload: "Upload",
      description: "Description",
      yourEmail: "Your e-mail address",
      documentTitle: "Document title",
      summary: "Summary",
      uploadPolicy: "Upload policy",
      collectionTitle: "Collection title",
      viewXMore: "View {{number}} more",
      yourName: "Your name",
      message: "Message",
      moreOptions: "More Options",
      removeCollection: "Remove Collection",
      removeFromCollection: "Remove from Collection",
      removeFromLibrary: "Remove from library",
      loading: "Loading...",
      about: "About",
      cancel: "Cancel",
      you: "You",
      and: "and",
      next: "Next",
      previous: "Previous",
      internalDocument: "Internal Document",
      internal_document: "Internal Document",
      infoPage: "Oops! You need to be logged in to see this page.",
      internal_collection: "Internal Collection",
      article: "Article",
      articles: "Articles",
      document: "Document",
      documents: "Documents",
      toolkit: "Toolkit",
      submit: "Submit",
      useStaffLogin: 'Please use the "Save the Children staff login" button located below',
      lifetime: "Historical",
      sixMonths: "Six months",
      threeMonths: "Three months",
      thisMonth: "This month",
      analyticsXResources: "Views for current {{amount}} resources",
      historicalViews: "Historical views"
    },
    toolkit: {
      chapter: "Chapter",
      chapters: "Chapters",
      chapterOf: "Chapter {{index}} of {{total}}",
      nextChapter: "Next chapter",
      previousChapter: "Previous chapter",
      toolkits: "Toolkits",
      download: "Download Material",
      chaptersAndResources: "Chapters and resources",
      partOf: "Part of the"
    },
    settings: {
      passwordValidity: "Your password has to be at least 6 characters long. Must contain at least one lower case letter, one upper case letter, one digit.",
      changePassword: "Change password",
      editAccount: "Edit Account",
      deleteMyAccount: "Delete my account",
      nameChangedSuccessfully: "Name changed successfully",
      passwordChangedSuccessfully: "Password changed successfully",
      inputs: {
        nameLabel: "First and last name",
        namePlaceholder: "Start typing",
        emailLabel: "E-mail",
        emailPlaceholder: "Start typing",
        passwordLabel: "Password",
        passwordPlaceholder: "Start typing",
        repeatPasswordLabel: "Repeat password",
        repeatPasswordPlaceholder: "Start typing",
        termsAndConditions: "Terms and conditions",
        privacyPolicy: "Privacy policy",
        termsApprovalText: "By submitting this form, I consent to the {0} and the {1}"
      }
    },
    subscriptions: {
      deleteThisSubscription: "Delete this subscription",
      addMore: "Add more",
      selectTopics: "Select Topics",
      allTopicsSelected: "All topics selected",
      selectKeywords: "Select Keywords",
      allKeywordsSelected: "All keywords selected",
      selectCountries: "Select Countries",
      allCountriesSelected: "All countries selected",
      selectPublishers: "Select Publishers",
      allPublishersSelected: "All publishers selected",
      selectCollections: "Select Collections",
      allCollectionsSelected: "All collections selected",
      selectContentTypes: "Select Content Types",
      allContentTypesSelected: "All content types selected",
      selectAuthors: "Select Authors",
      allAuthorsSelected: "All authors selected",
      selectFrequency: "Receive newsletters:",
      subscriptionCreated: "Subscription created",
      subscribeToNewsletter: "Subscribe to newsletter",
      nothingToSeeHere: "Nothing to see here",
      fetchingSubscriptions: "Fetching subscriptions"
    },
    icons: {
      link: "Link",
      addToLibrary: "Add to library",
      addedToLibrary: "Added to library",
      viewAndDownload: "View and Download"
    },
    internalCollections: {
      noCollections: "You haven't added any internal collections yet.",
      createNewCollection: "Create new collection",
      newCollection: "New collection",
      createCollectionLabel: "New collection title",
      setPrivate: "Mark collection as private",
      notInvited: "Collaborator(s) could not be invited"
    },
    editUserCollectionModal: {
      uploadFailed: "Something went wrong"
    },
    evaluationHub: {
      sourceOfFunds: "Source of Funds",
      contentType: "Content Type",
      studyType: "Study Type",
      ethicsApproval: "Ethics Approval",
      ethicsApprovalNumber: "Ethics Approval Number",
      averageScore: "Quality Score",
      quarter: "Quarter",
      design: "Design",
      purpose: "Purpose",
      conductedCommissionedBy: "Conducted Commissioned By",
      overallFindings: "Overall Findings",
      contact: "Contact",
      scorer: "Scorer",
      documentTitle: "Document Title"
    },
    dataDashboard: {
      title: "Data dashboard",
      intro: "Gain valuable insights about how documents <br /> are viewed. <a href='/frequently-asked-questions/#data-dashboard'>How does this work? </a>",
      infoHeading: "What do the numbers mean?",
      info: "<ul><li style='margin-top: 0.5rem;'>This month: all views from the first day of the current month up until today</li><li style='margin-top: 0.5rem;'>Three months: all views from today, going back to exactly three months</li><li style='margin-top: 0.5rem;'>Six months: all views from today, going back to exactly six months</li><li style='margin-top: 0.5rem;'>Historical views: all the remaining views up to the start of the last six months</li></ul><p style='margin-top: 1rem;'>The bar graph and table only show views and aggregated data for 50 resources at a time.</p><p style='margin-top: 1rem;'>Note that this page only shows views from August 31st, 2022, to the present. If you require information on earlier views, please email the Resource Centre Team.</p><p style='margin-top: 1rem;'>The page is updated hourly.</p>"
    }
  }
};
