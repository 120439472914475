export const path = (paths: string[], obj: { [key: string]: any }) => {
  let val = obj;
  let idx = 0;
  while (idx < paths.length) {
    if (val == null) {
      return;
    }
    val = val[paths[idx]];
    idx += 1;
  }

  return val;
};

/**
 * Checks if obj is an array.
 * Also supports proxy objects such as Mobx observables
 *
 * @param obj
 */

export const isArray = function(obj?: any): obj is any[] {
  return Array.isArray(obj) || typeof obj?.push === "function";
};

/**
 * Merge all values from source into destination
 */
export const merge = (source: object, destination: object) => {
  Object.keys(source).forEach(key => {
    destination[key] = source[key];
  });
};
