import { GAuthor } from "../graphqlQueryPartials";
import { GArticleModalView, GArticleListView } from "./article";
import { GDocumentModalView, GDocumentListView, GDocument } from "./document";

export const GUserCollectionModalView = `
  id
  databaseId
  slug
  title
  nodeType: __typename
  userCollectionFields {
    title
    collectionPosts {
      ... on Document {
        ${GDocumentModalView}
      }
      ... on Article {
        ${GArticleModalView}
      }
    }
  }
`;

export const GUserCollectionListView = `
  id
  databaseId
  slug
  title
  nodeType: __typename
  author {
    node {
      ${GAuthor}
    }
  }
  userCollectionFields {
    title
    collectionPosts {
      ... on Document {
        ${GDocumentListView}
      }
      ... on Article {
        ${GArticleListView}
      }
    }
  }
`;

export const GUserCollection = `
  id
  databaseId
  slug
  title
  link
  authorId
  nodeType: __typename
  contentTypeName
  modified
  author {
    node {
      ${GAuthor}
    }
  }
  userCollectionFields {
    title
    description
    collectionPosts {
      nodeType: __typename
      ... on Document {
        ${GDocument}
      }
      ... on Article {
        ${GArticleListView}
      }
    }
  }`;
