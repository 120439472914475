import { makeAutoObservable, runInAction } from "mobx";

import { Store } from "./Store";
import _ from "lodash";
import { ApiClient, GraphqlClient } from "~libs/api";
import { tx } from "~libs/i18n";
import { merge, removeAtIndex } from "~libs/utils";
import { ISingleWordpressArticle } from "~models/Article";
import { ISingleWordpressDocument } from "~models/Document";
import { formDataToApiData, IFormData } from "~models/FormData";
import { ISingleDocumentTaxonomy } from "~models/Taxonomies";
import { ISingleWordpressUserCollection, IFetchGraphqlUserCollection } from "~models/UserCollection";

export const COLLECTION_PAGE_SIZE = 6;

export class UserCollectionStore {
  private open: boolean;

  viewCollection: ISingleWordpressUserCollection | Object = {};

  viewRequestStatus: "idle" | "pending" | "error" = "idle";

  viewVisiblePosts = 0;

  updateCollection: ISingleWordpressUserCollection | Object = {};

  updateRequestStatus: "idle" | "pending" = "idle";

  deleteRequestStatus: "idle" | "pending" = "idle";

  removePostRequest: "idle" | "pending" = "idle";

  numberOfPages: number = 1;

  currentPage: number = 1;

  chunkedResults: ISingleWordpressUserCollection[][] = [];

  formData = {
    title: {
      value: "",
      method: value => !!value,
      valid: true
    },
    description: {
      value: "",
      method: value => !!value,
      valid: true
    }
  };

  get viewDocuments() {
    return (this.viewCollection?.userCollectionFields?.collectionPosts?.filter(post => post.nodeType === "Document") || []) as ISingleWordpressDocument[];
  }

  get viewPopularTags() {
    const taxonomies = this.viewDocuments
      .map(document => {
        const taxonomies = document?.documentTopics.topics
          .concat(document?.documentKeywords?.keywords)
          .concat(document?.documentCountries?.countries)
          .concat(document?.documentRegions?.regions)
          .concat(document?.documentPublishers?.publishers)
          .concat(document?.documentContentTypes?.contentTypes)
          .concat(document?.documentLanguages?.languages)
          .concat(document?.documentAuthors?.authors);
        //.concat(document?.documentLicenses?.licenses);
        return taxonomies;
      })
      .flat();

    const taxCountMap: {
      taxonomy: ISingleDocumentTaxonomy;
      count: number;
    }[] = Object.values(
      taxonomies.reduce((acc, tax) => {
        if (!acc[tax.id]) {
          acc[tax.id] = { taxonomy: tax, count: 0 };
        }
        acc[tax.id].count++;
        return acc;
      }, {})
    );

    taxCountMap.sort((a, b) => a.count - b.count);
    return taxCountMap.map(item => item.taxonomy);
  }

  get viewPostCount() {
    return this.viewCollection?.userCollectionFields?.collectionPosts?.length || 0;
  }

  get viewShowMore() {
    return this.viewPostCount > this.viewVisiblePosts;
  }

  collectPopularTags = documents => {
    let collectionTags = [];
    documents?.map(document => {
      const taxonomies = document?.documentTopics
        .concat(document?.documentKeywords)
        .concat(document?.documentCountries)
        .concat(document?.documentRegions)
        .concat(document?.documentPublishers)
        .concat(document?.documentContentTypes)
        .concat(document?.documentLanguages)
        .concat(document?.documentAuthors);
      //.concat(document?.documentLicenses);
      taxonomies.map(taxonomy => {
        if (collectionTags.some(tag => tag.name === taxonomy.name)) {
          let taxonomyIndex = collectionTags.findIndex(tag => tag.name === taxonomy.name);
          collectionTags[taxonomyIndex].occurrences++;
        } else {
          taxonomy.occurrences = 1;
          collectionTags.push(taxonomy);
        }
      });
    });

    return collectionTags.sort((first, second) => first.occurrences + second.occurrences);
  };

  get isOpen() {
    return this.open;
  }

  get isUploadValid() {
    return !Object.keys(this.formData || {}).find(key => !this.formData[key].valid);
  }

  constructor(private store: Store, private client: ApiClient, private graphqlClient: GraphqlClient) {
    makeAutoObservable(this);
  }

  onOpenUpdateModal = (collection: ISingleWordpressUserCollection) => {
    this.updateCollection = collection;
    this.collectionToFormData(collection);
    this.open = true;
  };

  onCloseUpdateModal = () => {
    this.open = false;
  };

  collectionToFormData = (collection: ISingleWordpressUserCollection) => {
    this.formData.title.value = collection.userCollectionFields.title;
    this.formData.description.value = collection.userCollectionFields.description;
  };

  onUpdate = async () => {
    if (this.updateRequestStatus == "pending") {
      return;
    }
    this.updateRequestStatus = "pending";

    const collection = this.updateCollection;
    const data: any = formDataToApiData(this.formData);

    const response = await this.client.updateUserCollection({
      id: collection.databaseId,
      ...data
    });
    if (response.ok) {
      const getResponse = await this.graphqlClient.getUserCollection(collection.id);

      const { data, errors } = await getResponse.json();
      if (!errors) {
        this.viewCollection = data.userCollection;
        merge(data.userCollection, collection.userCollectionFields);
        await this.store.userStore.fetchUserCollections();
        this.store.libraryStore.reset();
      }

      this.onCloseUpdateModal();
    } else {
      this.store.uiStore.showGlobalMessage(tx("EditUserCollectionModal.uploadFailed"), "error");
    }

    this.updateRequestStatus = "idle";
  };

  onDelete = async (databaseId: string) => {
    if (this.deleteRequestStatus == "pending") {
      return;
    }
    this.deleteRequestStatus = "pending";

    const response = await this.client.deleteUserCollection({
      id: databaseId
    });

    if (response.ok) {
    } else {
      this.store.uiStore.showGlobalMessage(tx("EditUserCollectionModal.uploadFailed"), "error");
    }

    this.deleteRequestStatus = "idle";
  };

  onRemovePostFromCollection = async (collection: ISingleWordpressUserCollection | IFetchGraphqlUserCollection, postDatabaseId: number) => {
    if (this.removePostRequest == "pending") {
      return;
    }
    this.removePostRequest = "pending";

    const response = await this.client.removePostFromUserCollection(collection.databaseId, postDatabaseId);
    if (response.ok) {
      const index = collection.userCollectionFields.collectionPosts.findIndex(post => post.databaseId === postDatabaseId);

      collection.userCollectionFields.collectionPosts = removeAtIndex(collection.userCollectionFields.collectionPosts, index);

      if (collection.userCollectionFields.collectionPosts.length === 0) {
        //remove the collection too and jump back to collections page
        // this.onDelete(collection.databaseId)
      }
    }

    this.removePostRequest = "idle";
  };

  onRemovePostFromAllUserCollections = async (postToRemove: ISingleWordpressArticle | ISingleWordpressDocument) => {
    if (this.removePostRequest == "pending") {
      return;
    }
    this.removePostRequest = "pending";

    this.store.userStore?.userCollections?.map(async collection => {
      const response = await this.client.removePostFromUserCollection(collection.databaseId, postToRemove);
      if (response.ok) {
        runInAction(() => {
          collection.userCollectionFields.collectionPosts = collection?.userCollectionFields?.collectionPosts?.filter(post => post.id !== postToRemove.id);
        });
      }
    });
    this.removePostRequest = "idle";
  };

  setCurrentPage = (index: number) => {
    this.currentPage = index;
  };

  setAndValidate = (data: IFormData<any>, value: any) => {
    data.value = value;
    this.validate(data);
  };

  validate = (data: IFormData<any>) => {
    data.valid = data.method(data.value);
  };

  fetchViewCollection = async (id: string) => {
    if (this.viewRequestStatus === "pending") {
      return;
    }
    this.viewRequestStatus = "pending";

    const response = await this.graphqlClient.getUserCollection(id);
    runInAction(async () => {
      if (response.ok) {
        const { data, errors } = await response.json();
        runInAction(() => {
          if (errors) {
            this.viewRequestStatus = "error";
          } else {
            this.viewCollection = data.userCollection;
            if (!this.viewVisiblePosts) {
              this.viewVisiblePosts = Math.min(8, this.viewPostCount);
            }
            this.numberOfPages = Math.ceil(data.userCollection?.userCollectionFields?.collectionPosts?.length / COLLECTION_PAGE_SIZE);
            this.chunkedResults = _.chunk(data.userCollection?.userCollectionFields?.collectionPosts, COLLECTION_PAGE_SIZE);
            this.viewRequestStatus = "idle";
          }
        });
      } else {
        this.viewRequestStatus = "error";
      }
    });
  };
}
