import { makeAutoObservable, runInAction } from "mobx";
import _ from "lodash";
import { Store } from "./Store";

import { persist } from "mobx-persist";
import { tx } from "~libs/i18n";
import { ISingleWordpressArticle } from "~models/Article";
import { ISingleWordpressCollection } from "~models/Collection";
import { ISingleWordpressDocument } from "~models/Document";
import { ISingleWordpressInternalDocument } from "~models/InternalDocument";
import { ISingleWordpressToolkit } from "~models/Toolkit";
import { IAuthenticationUser } from "~models/User";
import { IFetchGraphqlUserCollection } from "~models/UserCollection";
import { GraphqlClient } from "~libs/api";
import { removeAtIndex } from "~libs/utils";

type UserLibraryPost = ISingleWordpressInternalDocument | ISingleWordpressDocument | ISingleWordpressCollection | ISingleWordpressArticle | ISingleWordpressToolkit;

export class UserStore {
  userStoreHydrated: boolean;

  currentUser: IAuthenticationUser;

  publicUser: boolean = false;

  userCollections: IFetchGraphqlUserCollection[] = [];

  myLibrary: IFetchGraphqlUserCollection;

  myLibraryTabActive: boolean = true;

  @persist
  showMoreOpen: boolean = false;

  showMoreOpenId: number = -1;

  newName: string = "";

  newNameErrorMessage: string = "";

  newNameInvalid: boolean = false;

  updateUserMessage: string = "";

  userLibraryModified: string = "";

  userLibraryPosts: UserLibraryPost[] = undefined;

  userLibraryRequestStatus: "idle" | "pending" = "idle";

  updateUserMessageType: "error" | "success";

  fetchUserLibraryStatus: "idle" | "pending" = "idle";

  toggleShowMore = id => {
    if (id) {
      if (this.showMoreOpenId === id) {
        this.showMoreOpenId = -1;
      } else {
        this.showMoreOpenId = id;
      }
    } else {
      this.showMoreOpenId = -1;
    }
    this.showMoreOpen = !this.showMoreOpen;
  };

  setShowMoreFalse = () => {
    this.showMoreOpen = false;
    this.showMoreOpenId = -1;
  };

  get uniqueUserCollectionPosts() {
    const posts = this.myLibrary?.userCollectionFields?.collectionPosts?.filter(post => !!post) || [];

    return _.uniqBy(posts, function(post) {
      return post.slug;
    });
  }

  get numberOfUserResources() {
    return this.uniqueUserCollectionPosts.length || 0;
  }

  get numberOfUserCollections() {
    return this.userCollections?.length || 0;
  }

  get isStaffUser() {
    const staffRoles = ["staff_admin", "staff", "administrator"];
    return this.currentUser?.roles?.nodes?.some(role => staffRoles.includes(role.name));
  }

  constructor(private rootStore: Store, private graphqlClient: GraphqlClient) {
    makeAutoObservable(this);
    this.userStoreHydrated = false;
  }

  setHydrated = (hydrated: boolean) => {
    this.userStoreHydrated = hydrated;
  };

  toggleProfileTabs = () => {
    this.myLibraryTabActive = !this.myLibraryTabActive;
    this.showMoreOpen = false;
    this.fetchUserCollections();
  };

  /**
   * Set the current authenticated User.
   */
  setCurrentUser = (user: IAuthenticationUser) => {
    this.currentUser = user;
    this.publicUser = false;

    if (user?.userCollections) {
      this.populateUserCollections(user.userCollections?.nodes);
    }
  };

  /**
   * Remove the current user from local storage.
   */
  removeCurrentUser = () => {
    this.currentUser = {};
    this.userCollections = [];
    this.publicUser = true;
  };

  populateUserCollections = (userCollections: IFetchGraphqlUserCollection[]) => {
    this.userCollections = userCollections?.filter(collection => collection?.title !== "default");
    this.myLibrary = userCollections?.find(collection => collection?.title === "default");
  };

  isCurrentUserCollectionOwner = (authorId: string) => {
    return authorId && this.currentUser?.id === authorId;
  };

  /**
   * Fetch current user collections.
   */
  fetchUserCollections = async () => {
    const { loginStore } = this.rootStore;
    const { handleLogout } = loginStore;

    if (this.fetchUserLibraryStatus === "pending") return;

    this.fetchUserLibraryStatus = "pending";

    const response = await this.graphqlClient.getCurrentUserCollections();

    if (response.ok) {
      const { data, errors } = await response.json();
      if (errors) {
        handleLogout();
      } else {
        if (data?.viewer?.userCollections) this.populateUserCollections(data?.viewer?.userCollections?.nodes);
      }
    } else {
      console.error(response);
    }
    this.fetchUserLibraryStatus = "idle";
  };

  fetchCurrentUser = async () => {
    const response = await this.graphqlClient.getCurrentUser();

    if (response.ok) {
      const { data, errors } = await response.json();
      if (errors) {
        console.error(errors);
      } else {
        this.setCurrentUser(data.viewer);
      }
    } else {
      console.error(response);
    }

    return true;
  };

  setNewName = (newName: string) => {
    if (newName?.length === 0) {
      this.validateNewName();
    }
    this.newName = newName;
  };

  validateNewName = () => {
    this.newNameInvalid = !this.rootStore.validationStore.validateNewName(this.newName);
    if (this.newNameInvalid) {
      this.newNameErrorMessage = this.rootStore.validationStore.getNewNameErrorMessage(this.newName);
    }
  };

  accountDetailstoUpdate = () => {
    const { validatePassword, validatePasswordRepeat, password } = this.rootStore.registerStore;
    this.newName.length && this.validateNewName();
    password.length && validatePassword();
    password.length && validatePasswordRepeat();
  };

  updateUser = async () => {
    this.accountDetailstoUpdate();
    const { passwordInvalid, passwordRepeatInvalid, password } = this.rootStore.registerStore;
    if (this.newName.length !== 0 && !this.newNameInvalid) {
      await this.updateName();
    }
    if (password.length !== 0 && !passwordInvalid && !passwordRepeatInvalid) {
      await this.updatePassword();
    }
  };

  showErrorMessage = () => {
    this.updateUserMessage = tx("UploadDocumentsModal.errorGeneric");
    this.updateUserMessageType = "error";
    setTimeout(() => {
      this.updateUserMessage = "";
    }, 2500);
  };

  updateName = async () => {
    const response = await this.graphqlClient.updateName(this.currentUser.id, this.newName);
    if (response.ok) {
      const { data, errors } = await response.json();
      if (errors) {
        this.showErrorMessage();
      } else {
        this.fetchCurrentUser();
        this.newName = "";
        this.updateUserMessage = tx("settings.nameChangedSuccessfully");
        this.updateUserMessageType = "success";
        setTimeout(() => {
          this.updateUserMessage = "";
        }, 2500);
      }
    } else {
      console.error(response);
    }
  };

  updatePassword = async () => {
    const { password } = this.rootStore.registerStore;

    const response = await this.graphqlClient.updatePassword(this.currentUser.id, password);
    if (response.ok) {
      const { data, errors } = await response.json();
      if (errors) {
        this.showErrorMessage();
      } else {
        this.updateUserMessage = tx("settings.passwordChangedSuccessfully");
        this.updateUserMessageType = "success";
        setTimeout(() => {
          this.updateUserMessage = "";
        }, 2500);
      }
    } else {
      console.error(response);
    }
  };

  getUserLibrary = async () => {
    const response = await this.graphqlClient.getUserLibraryModified();

    runInAction(async () => {
      if (response.ok) {
        const { data, errors } = await response.json();
        runInAction(() => {
          if (errors) {
            console.error(errors);
          } else {
            const modified = data?.viewer?.userLibrary?.modified;

            if (modified !== this.userLibraryModified) {
              this.userLibraryModified = modified;
              this.fetchUserLibrary();
            }
          }
        });
      }
    });
  };

  /**
   * TODO: Pagination not possible with this approach?
   */
  fetchUserLibrary = async () => {
    const response = await this.graphqlClient.getUserLibrary();

    return runInAction(async () => {
      if (response.ok) {
        const { data, errors } = await response.json();

        if (errors) {
          console.error(errors);
        } else {
          this.userLibraryPosts = [...(data.viewer.userLibrary.userLibraryPosts ?? [])];

          return data;
        }
      }
    });
  };

  fetchUserLibraryModified = async () => {
    if (this.userLibraryRequestStatus !== "idle") {
      return;
    }

    this.userLibraryRequestStatus = "pending";

    const response = await this.graphqlClient.getUserLibraryModified();

    return runInAction(async () => {
      if (response.ok) {
        const { data, errors } = await response.json();

        if (errors) {
          this.userLibraryRequestStatus = "idle";
          console.error(errors);
        } else {
          this.userLibraryRequestStatus = "idle";
          return data;
        }
      }
    });
  };

  addPostToUserLibrary = async (postId: number) => {
    if (this.userLibraryRequestStatus !== "idle") {
      return;
    }

    this.userLibraryRequestStatus = "pending";

    const response = await this.graphqlClient.addPostToUserLibrary(postId);

    return runInAction(async () => {
      if (response.ok) {
        const { data, errors } = await response.json();

        return runInAction(() => {
          if (errors) {
            this.userLibraryRequestStatus = "idle";
            return false;
          } else {
            const { success, message } = data?.addPostToUserLibrary;

            this.userLibraryRequestStatus = "idle";

            return success;
          }
        });
      }
    });
  };

  removePostFromUserLibrary = async (postId: number) => {
    if (this.userLibraryRequestStatus !== "idle") {
      return;
    }

    this.userLibraryRequestStatus = "pending";

    const response = await this.graphqlClient.removePostFromUserLibrary(postId);

    return runInAction(async () => {
      if (response.ok) {
        const { data, errors } = await response.json();

        return runInAction(() => {
          if (errors) {
            this.userLibraryRequestStatus = "idle";
            console.error(errors);
            return false;
          } else {
            const { success, message } = data?.removePostFromUserLibrary;

            if (success) {
              const index = this.userLibraryPosts.findIndex(post => post.databaseId === postId || parseInt(post.id) === postId);

              this.userLibraryPosts = removeAtIndex(this.userLibraryPosts, index);
            }

            this.userLibraryRequestStatus = "idle";
            return success;
          }
        });
      }
    });
  };
}
