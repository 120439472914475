import { makeAutoObservable, toJS } from "mobx";

import _ from "lodash";

import { Store } from "./Store";
import { ApiClient } from "~libs/api";
import { IArticleCollectionSliderObject, IArticleCollectionSliderRow } from "~models/Article";
import { ISingleWordpressCollection } from "~models/Collection";
import { ISingleWordpressDocument } from "~models/Document";

export class ArticleCollectionStore {
  constructor(private rootStore: Store, private client: ApiClient) {
    makeAutoObservable(this);
  }
  articleCollectionSlider: IArticleCollectionSliderObject[] = toJS([]);

  subscriptionCollection: ISingleWordpressCollection;

  openedAtScrollPosition;

  initializeCollectionSlider = (rows: IArticleCollectionSliderRow[]) => {
    let id = this.articleCollectionSlider.push({} as IArticleCollectionSliderObject) - 1;
    this.populateCollectionSlider(rows, id);
    this.clearFilter(false, id);
    this.setFilter(rows, id);
  };

  setSubscriptionCollection = (collection: ISingleWordpressCollection) => {
    this.subscriptionCollection = collection;
  };

  populateCollectionSlider = (rows: IArticleCollectionSliderRow[], id: number) => {
    this.articleCollectionSlider[id].initialRows = rows;
    this.articleCollectionSlider[id].collections = rows;
    this.articleCollectionSlider[id].id = id;
    this.articleCollectionSlider[id].currentRowIdx = 0;
    this.articleCollectionSlider[id].currentRowDocuments = [];
    this.articleCollectionSlider[id].menuOpen = false;
    this.articleCollectionSlider[id].reseted = true;

    const isDesktop = typeof window !== "undefined" && window.innerWidth > 600;

    const startIdx = rows.length >= 3 ? (isDesktop ? 2 : 1) : 0;
    if (rows[startIdx]) {
      this.setCurrentRow(null, startIdx, id);
    }
  };

  setFilter = (rows: IArticleCollectionSliderRow[], id: number) => {
    let filterDocuments = rows.flatMap(r => r?.collection?.collectionFields?.documents);
    filterDocuments = filterDocuments.filter(document => document !== null);
    this.articleCollectionSlider[id].taxonmies = this.getTaxonomiesFromDocuments(filterDocuments);

    let taxonomies = this.articleCollectionSlider[id].taxonmies;
    let keywords = [];
    taxonomies.forEach((tax, i) => {
      if (!keywords.some(t => t.id === tax.id)) {
        keywords.push({
          id: tax.id,
          value: tax.transName || tax.name,
          slug: tax.slug,
          numberOfDocuments: tax.count,
          selected: false
        });
      }
    });
    this.articleCollectionSlider[id].keywords = keywords;
  };
  copy = (value: any) => {
    return _.cloneDeep(value);
  };

  getUnselectedArray = <T extends {}>(array: T[]): T[] => {
    return array.map(item => {
      return { ...item, selected: false };
    });
  };
  resetFilter = id => {
    this.articleCollectionSlider[id].reseted = true;
    this.articleCollectionSlider[id].keywords = this.getUnselectedArray(this.articleCollectionSlider[id].keywords);

    let lengthChanged = this.articleCollectionSlider?.[id].collections?.length !== this.articleCollectionSlider?.[id]?.initialRows.length;
    this.articleCollectionSlider[id].collections = this.copy(this.articleCollectionSlider?.[id]?.initialRows);
    if (lengthChanged) {
      this.setCurrentRow(null, this.articleCollectionSlider[id].currentRowIdx, id, true);
    } else {
      this.setCurrentRow(null, this.articleCollectionSlider[id].currentRowIdx, id, false);
    }
  };

  applyFilter = (id: number) => {
    this.articleCollectionSlider[id].reseted = false;
    let indexToRemove = [];
    this.articleCollectionSlider[id].collections = this.copy(this.articleCollectionSlider?.[id]?.initialRows);
    let keywords = this.articleCollectionSlider[id].keywords;
    this.copy(this.articleCollectionSlider[id].initialRows).forEach((collection, index) => {
      let documents = collection.collection.collectionFields.documents;
      let filteredDocuments = documents ? documents : [];

      let filterTaxonomy = (taxonomies, field, subField): ISingleWordpressDocument => {
        let selectedTaxonomies = taxonomies.filter(a => a.selected);
        if (selectedTaxonomies.length === 0) {
          return;
        }
        filteredDocuments = filteredDocuments.filter((document: ISingleWordpressDocument) => {
          let documentContainsSelectedTaxonomy = document[field] && document[field][subField]?.some(field => selectedTaxonomies.find(taxonomy => field.slug == taxonomy.slug));
          return documentContainsSelectedTaxonomy;
        });
      };

      filterTaxonomy(keywords, "documentKeywords", "keywords");
      if (filteredDocuments.length) {
        this.articleCollectionSlider[id].collections[index].collection.collectionFields.documents = filteredDocuments;
      } else {
        indexToRemove.push(index);
      }
    });
    indexToRemove.reverse().forEach(i => {
      this.articleCollectionSlider[id].collections.splice(i, 1);
    });

    if (this.articleCollectionSlider[id].collections.length === this.articleCollectionSlider[id].initialRows.length) {
      this.setCurrentRow(null, this.articleCollectionSlider[id].currentRowIdx, id, false);
    } else {
      this.setCurrentRow(null, this.articleCollectionSlider[id].currentRowIdx, id, true);
    }
  };

  setCurrentRow = (row: IArticleCollectionSliderRow, idx: number, id?: number, sizeChange?: boolean) => {
    if (id === undefined) return;
    if (sizeChange || idx >= this.articleCollectionSlider?.[id]?.collections?.length) {
      length = this.articleCollectionSlider?.[id]?.collections?.length;
      if (length % 2 === 0 && length > 2) {
        idx = Math.ceil(length / 2);
      } else {
        idx = Math.floor(length / 2);
      }
    }
    this.articleCollectionSlider[id].currentRow = this.articleCollectionSlider?.[id]?.collections?.[idx];
    this.articleCollectionSlider[id].currentRowIdx = idx;
    this.getVissibleDocuments(id);
  };

  getVissibleDocuments = (id: number) => {
    let documents = this.articleCollectionSlider?.[id]?.currentRow?.collection?.collectionFields?.documents;
    this.articleCollectionSlider[id].currentRowDocuments = documents;
  };

  getTaxonomiesFromDocuments = (documents: ISingleWordpressDocument[]) => {
    let taxonomies = [];
    documents.forEach(doc => (taxonomies = taxonomies.concat([...doc?.documentKeywords?.keywords])));
    return taxonomies;
  };

  clearFilter = (saveSelected: boolean, id: number) => {
    this.articleCollectionSlider[id].keywords = saveSelected ? this.articleCollectionSlider[id].keywords.filter(tax => tax.selected) : [];
  };

  openMenu = (id: number) => {
    if (this.articleCollectionSlider?.[id]?.menuOpen === undefined) return;
    this.articleCollectionSlider[id].menuOpen = !this.articleCollectionSlider?.[id]?.menuOpen;
    if (this.articleCollectionSlider?.[id]?.menuOpen) {
      this.rootStore.sectionsContainerStore.hasOpenOverlay = true;
      this.setScrollPos();
    } else {
      this.rootStore.sectionsContainerStore.hasOpenOverlay = false;
    }
  };

  setScrollPos = () => {
    this.openedAtScrollPosition = window.scrollY;
  };
}
