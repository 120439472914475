import loadable from "@loadable/component";

export const CalendarSmall = require("../../../static/icons/calendar_small.svg");
export const CancelLight = loadable(() => import("../../../static/icons/cancel_light.svg"));
export const CancelNormal = loadable(() => import("../../../static/icons/cancel_normal.svg"));
export const CancelRedBgWhite = loadable(() => import("../../../static/icons/cancel_red_bg_white.svg"));
export const CategoriesSmall = loadable(() => import("../../../static/icons/categories_small.svg"));
export const ChevronDownLarge = loadable(() => import("../../../static/icons/chevronDown_large.svg"));
export const ChevronDownSmall = loadable(() => import("../../../static/icons/chevronDown_small.svg"));
export const ChevronUpLarge = loadable(() => import("../../../static/icons/chevronUp_large.svg"));
export const ChevronUpSmall = loadable(() => import("../../../static/icons/chevronUp_small.svg"));
export const CiteSmall = loadable(() => import("../../../static/icons/cite_small.svg"));
export const CompassSmall = loadable(() => import("../../../static/icons/compass_small.svg"));
export const ContentTypeSmall = loadable(() => import("../../../static/icons/contenttype_small.svg"));
export const CopySmall = loadable(() => import("../../../static/icons/copy_small.svg"));
export const CornerArrowSmall = loadable(() => import("../../../static/icons/corner_arrow_small.svg"));
export const DownloadSmall = loadable(() => import("../../../static/icons/download_small.svg"));
export const DownloadSmallWhite = loadable(() => import("../../../static/icons/download_small_white.svg"));
export const DropdownSmall = loadable(() => import("../../../static/icons/dropdown_small.svg"));
export const EmailSmall = require("../../../static/icons/email_small.svg");
export const ExternalLinkSmall = loadable(() => import("../../../static/icons/externallink_small.svg"));
export const EyeSmall = loadable(() => import("../../../static/icons/eye_small.svg"));
export const FacebookSmall = require("../../../static/icons/facebook_small.svg");
export const FileMinusSmall = loadable(() => import("../../../static/icons/file_minus_small.svg"));
export const FilePlusSmall = loadable(() => import("../../../static/icons/file_plus_small.svg"));
export const FileTextSmall = require("../../../static/icons/file_text_small.svg");
export const FilterSmall = loadable(() => import("../../../static/icons/filter_small.svg"));
export const FilterSmallActive = loadable(() => import("../../../static/icons/filter_small_active.svg"));
export const HashSmall = loadable(() => import("../../../static/icons/hash_small.svg"));
export const HiddenSmall = loadable(() => import("../../../static/icons/hidden_small.svg"));
export const LangLarge = require("../../../static/icons/lang_large.svg");
export const LangSmall = require("../../../static/icons/lang_small.svg");
export const LibraryLarge = loadable(() => import("../../../static/icons/library_large.svg"));
export const LibrarySmall = require("../../../static/icons/library_small.svg");
export const LinkSmall = require("../../../static/icons/link_small.svg");
export const LinkedinSmall = require("../../../static/icons/linkedin_small.svg");
export const LogoutSmall = loadable(() => import("../../../static/icons/logout_small.svg"));
export const LockSmall = loadable(() => import("../../../static/icons/lock_small.svg"));
export const MenuLarge = require("../../../static/icons/menu_large.svg");
export const MoreSmallWhite = loadable(() => import("../../../static/icons/more_small_white.svg"));
export const MoreSmall = loadable(() => import("../../../static/icons/more_small.svg"));
export const NextLarge = require("../../../static/icons/next_large.svg");
export const PinLarge = require("../../../static/icons/pin_large.svg");
export const PinSmall = loadable(() => import("../../../static/icons/pin_small.svg"));
export const PreviousLarge = loadable(() => import("../../../static/icons/previous_large.svg"));
export const PublisherSmall = loadable(() => import("../../../static/icons/publisher_small.svg"));
export const ReCaptchaIcon = loadable(() => import("../../../static/icons/re_captcha.svg"));
export const SearchLarge = require("../../../static/icons/search_large.svg");
export const SearchSmall = loadable(() => import("../../../static/icons/search_small.svg"));
export const SettingsSmall = loadable(() => import("../../../static/icons/settings_small.svg"));
export const ShareSmall = require("../../../static/icons/share_small.svg");
export const Spinner = loadable(() => import("../../../static/icons/spinner.svg"));
export const SubscribeSmall = loadable(() => import("../../../static/icons/subscribe_small.svg"));
export const TimeSmall = loadable(() => import("../../../static/icons/time_small.svg"));
export const TrashSmall = loadable(() => import("../../../static/icons/trash_small.svg"));
export const TrashSmallWhite = loadable(() => import("../../../static/icons/trash_small_white.svg"));
export const TrashSmallRed = loadable(() => import("../../../static/icons/trash_small_red.svg"));
export const TwitterSmall = require("../../../static/icons/x_logo.svg");
export const UserSmall = loadable(() => import("../../../static/icons/user_small.svg"));
export const UserDefaultImage = loadable(() => import("../../../static/icons/user_default_image.svg"));
export const UploadSmall = loadable(() => import("../../../static/icons/upload_small.svg"));
export const ArrowUp = loadable(() => import("../../../static/icons/ArrowUp.svg"));
export const Play = loadable(() => import("../../../static/icons/play.svg"));
export const BackSmall = loadable(() => import("../../../static/icons/back_small.svg"));
