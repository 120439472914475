import { makeAutoObservable, toJS } from "mobx";

import { Store } from "./Store";
import { ISingleWordpressDocument } from "~models/Document";
import { ISingleDocumentTaxonomy } from "~models/Taxonomies";

export class CollectionStore {
  rootStore: Store;

  constructor(rootStore: Store) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  // Pagination variables
  numberOfDocumentResults: number = 0;

  numberOfPages: number = 1;

  currentPage: number = 1;

  // Collection result variables
  paginatedDocuments: ISingleWordpressDocument[][] = [];

  initialDocuments: ISingleWordpressDocument[] = [];

  searchQuery: string = "";

  // Collection result functions
  initCollectionStore = (documents: ISingleWordpressDocument[]) => {
    this.initialDocuments = documents;
    this.applySortingAndUpdatePaginatedDocuments(documents);
    if (documents) {
      this.rootStore.filterStore.initFilterStore(this.getTaxonomiesFromDocuments(documents), [], null, true);
    }
  };

  paginateDocumentsAndRecountResults = (documents: ISingleWordpressDocument[]) => {
    const pageSize = 6;
    let chunkedDocuments = [];
    let i = 0;

    while (i < documents?.length) {
      chunkedDocuments.push(documents.slice(i, (i += pageSize)));
    }
    this.paginatedDocuments = chunkedDocuments.length > 0 ? chunkedDocuments : [[]];
    this.numberOfPages = Math.max(chunkedDocuments.length, 1);
    this.numberOfDocumentResults = documents && documents.length;
  };

  getTaxonomiesFromDocuments = (documents: ISingleWordpressDocument[]) => {
    let taxonomies: ISingleDocumentTaxonomy[] = [];
    documents.forEach(
      doc =>
        (taxonomies = taxonomies.concat([
          ...doc?.documentAuthors?.authors,
          ...doc?.documentContentTypes?.contentTypes,
          ...doc?.documentPublishers?.publishers,
          ...doc?.documentKeywords?.keywords,
          ...doc?.documentTopics?.topics,
          ...doc?.documentCountries?.countries,
          ...doc?.documentRegions?.regions,
          ...doc?.documentLanguages?.languages
          //...doc?.documentLicenses?.licenses
        ]))
    );
    return taxonomies;
  };

  // Pagination functions
  setPage = (number: number) => {
    this.currentPage = number;
  };

  // Search functions
  setSearchQuery = (val: string) => {
    this.searchQuery = val;
  };

  toggleSearch = (e: KeyboardEvent) => {
    if (e.keyCode === 13) {
      // Perform the search.
      this.applyFilter();
    }
  };

  applyFilter = () => {
    const {
      topics,
      authors,
      publishers,
      contentTypes,
      countries,
      regions,
      languages,
      mediaFormats,
      keywords,
      //licenses,
      publicationYears
    } = this.rootStore.filterStore;

    let filteredDocuments = this.initialDocuments ? this.initialDocuments.slice() : [];
    if (!filteredDocuments || !filteredDocuments.length) {
      filteredDocuments = this.rootStore.searchStore.documentResults;
    }
    let filterTaxonomy = (taxonomies, field, subField) => {
      let selectedTaxonomies = taxonomies.filter(a => a.selected);
      if (selectedTaxonomies.length === 0) {
        return;
      }
      filteredDocuments = filteredDocuments.filter((document: ISingleWordpressDocument) => {
        let documentContainsSelectedTaxonomy = document[field] && document[field][subField]?.some(field => selectedTaxonomies.find(taxonomy => field.slug == taxonomy.slug));

        return documentContainsSelectedTaxonomy;
      });
    };

    let filterPublicationYears = publicationYears => {
      if (publicationYears?.length > 0) {
        filteredDocuments = filteredDocuments.filter((document: ISingleWordpressDocument) => {
          return publicationYears.some(year => year.value == document.documentFields.publicationYear);
        });
      }
    };

    let filterByTextQuery = (query: string) => {
      filteredDocuments = filteredDocuments.filter((document: ISingleWordpressDocument) => {
        let lowerCasedQuery = query.toLowerCase();
        return document.title.toLocaleLowerCase().includes(lowerCasedQuery) || document.documentFields.abstract.toLowerCase().includes(lowerCasedQuery);
      });
    };

    filterPublicationYears(publicationYears);
    filterTaxonomy(countries, "documentCountries", "countries");
    filterTaxonomy(regions, "documentRegions", "regions");
    filterTaxonomy(keywords, "documentKeywords", "keywords");
    filterTaxonomy(contentTypes, "documentContentTypes", "contentTypes");
    filterTaxonomy(publishers, "documentPublishers", "publishers");
    filterTaxonomy(authors, "documentAuthors", "authors");
    filterTaxonomy(topics, "documentTopics", "topics");
    filterTaxonomy(languages, "documentLanguages", "languages");
    //filterTaxonomy(licenses, "documentLicenses", "Licenses")
    filterByTextQuery(this.searchQuery);
    this.applySortingAndUpdatePaginatedDocuments(filteredDocuments);
  };

  sortByPriorization = (documents: ISingleWordpressDocument[], prioritize) => {
    let peerReviewed = prioritize.find(p => p.slug === "peer_reviewed")?.selected ?? false;
    let recommended = prioritize.find(p => p.slug === "recommended")?.selected ?? false;

    return documents.sort((a, b) => {
      let firstScore = 0;
      let secondScore = 0;

      if (peerReviewed) {
        firstScore += a.documentFields.documentFlares.peerReviewed ? 1 : 0;
        secondScore += b.documentFields.documentFlares.peerReviewed ? 1 : 0;
      }

      if (recommended) {
        firstScore += a.documentFields.documentFlares.recommended == recommended ? 1 : 0;
        secondScore += a.documentFields.documentFlares.recommended == recommended ? 1 : 0;
      }

      return secondScore - firstScore;
    });
  };

  sortBySelectedSortOption = (documents: ISingleWordpressDocument[], sortingKey: string) => {
    let sortedResults: ISingleWordpressDocument[] = [];
    if (sortingKey === "Alphabetical") {
      sortedResults = toJS(documents)
        .slice()
        .sort(this.rootStore.searchStore.sortAlphabetical);
    }
    if (sortingKey === "Latest") {
      sortedResults = toJS(documents)
        .slice()
        .sort(this.rootStore.searchStore.sortLatest);
    }
    if (sortingKey === "Relevance") {
      sortedResults = toJS(documents).slice();
    }
    return sortedResults;
  };

  applySortingAndUpdatePaginatedDocuments = (documentsInput?: ISingleWordpressDocument[]) => {
    const { prioritize, selectedSortingOption } = this.rootStore.filterStore;
    let documents = documentsInput ?? this.paginatedDocuments.flat();
    documents = this.sortByPriorization(documents, prioritize);
    documents = this.sortBySelectedSortOption(documents, selectedSortingOption!.key);
    this.paginateDocumentsAndRecountResults(documents);
  };
}
