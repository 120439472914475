import { makeAutoObservable, reaction } from "mobx";
import { Store } from "./Store";

import { globalHistory, navigate } from "@reach/router";
import { disableBodyScrollAndTranslate, isBrowser, resetBodyScroll } from "~libs/utils";
import { ILocation } from "~models/Location";

export type INavigationStoreMenu = "" | "header" | "toolkit" | "account" | "internalCollection";
export class NavigationStore {
  rootStore: Store;

  externalPreviousPath: boolean;

  location: ILocation = {
    hash: "",
    host: "",
    hostname: "",
    href: "",
    key: "",
    origin: "",
    pathname: "",
    port: "",
    protocol: "",
    search: "",
    state: null
  };

  isStartPage: boolean = false;

  headerHeight: number = 0;

  menuOpen: INavigationStoreMenu = "";

  get isInternalPage() {
    if (isBrowser()) {
      return window.location.href.includes("internal_document") || window.location.href.includes("internal_pdf");
    }
  }

  constructor(rootStore: Store, location: ILocation) {
    this.rootStore = rootStore;
    if (location) this.initNavigationStore(location);
    makeAutoObservable(this);
    reaction(
      () => this.location,
      () => (this.isStartPage = this.checkIfStartpage())
    );
  }

  initNavigationStore = (location: ILocation) => {
    location &&
      Object.keys(location).forEach(key => {
        if (this.location.hasOwnProperty(key)) this.location[key] = location[key];
      });

    this.isStartPage = this.checkIfStartpage();
    this.externalPreviousPath = location.state == null;
    globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        this.closeMenu();
      }
    });
  };

  checkIfStartpage = () => {
    const { languageStore } = this.rootStore;
    const { allLanguages } = languageStore;

    let checkValue = false;
    if (this.location.pathname === "/") {
      checkValue = true;
    } else {
      checkValue = !!allLanguages?.find(language => this.location.href === this.location.origin + "/" + language?.slug || this.location.href === this.location.origin + "/" + language?.slug + "/" || this.location.pathname.substr(1) === language?.slug || this.location.pathname.substr(1) === language?.slug + "/");
    }
    return checkValue;
  };

  toggleMenu = (menu: INavigationStoreMenu = "") => {
    this.menuOpen = this.menuOpen === menu ? "" : menu;

    if (!!this.menuOpen) {
      this.rootStore.sectionsContainerStore.hasOpenOverlay = true;
      disableBodyScrollAndTranslate(0, 0, 0);
    } else {
      this.rootStore.sectionsContainerStore.hasOpenOverlay = false;
      resetBodyScroll();
    }
  };

  closeMenu = () => {
    this.menuOpen = "";
    resetBodyScroll();
  };

  navigateToPreviousPageOrRoot = () => {
    const { origin, state } = this.location;

    if (this.externalPreviousPath) {
      navigate(origin);
    } else if (state) {
      state.referrer ? navigate(state.referrer) : navigate(origin);
    }
  };

  setLocation = (location: ILocation) => {
    this.location = location;
  };
}
