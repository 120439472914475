import { ISingleWordpressCollection } from "./Collection";
import { ISingleDocumentTaxonomy } from "./Taxonomies";

export interface ISubscriptionBody {
  id?: number;
  terms: { nodeType: string; id: number; name: string }[];
  frequency: string;
  email: string;
}

export interface ITaxonomySelection {
  value: string;
  id: number;
  selected: boolean;
  nodeType: string;
}

export interface ISubscriptionTaxonomy {
  items: ITaxonomySelection[];
  type: ISubscriptionTypes;
  nodeType: string;
  icon: string;
  alt: string;
  noSelectedValuesTx: string;
  allValuesSelectedTx: string;
  fetched: boolean;
}

export const documentTaxonomyToTaxonomySelection = (t: ISingleDocumentTaxonomy): ITaxonomySelection => ({
  value: t.name,
  nodeType: t.nodeType,
  selected: false,
  id: t.databaseId
});

export const collectionToTaxonomySelection = (t: ISingleWordpressCollection): ITaxonomySelection => ({
  value: t.title,
  nodeType: t.nodeType,
  selected: false,
  id: t.databaseId
});

export type ISubscriptionTaxonomyTypes = "documentTopics" | "documentKeywords" | "documentCountries" | "documentAuthors" | "documentContentTypes" | "documentPublishers";

export type ISubscriptionTypes = ISubscriptionTaxonomyTypes | "collections";

export const defaultSubscriptionTaxonomies: {
  [key in ISubscriptionTypes]: ISubscriptionTaxonomy;
} = {
  documentTopics: {
    items: [],
    type: "documentTopics",
    nodeType: "DocumentTopic",
    icon: "categories_small",
    alt: "categories",
    noSelectedValuesTx: "subscriptions.selectTopics",
    allValuesSelectedTx: "subscriptions.allTopicsSelected",
    fetched: false
  },
  documentKeywords: {
    items: [],
    type: "documentKeywords",
    nodeType: "DocumentKeyword",
    icon: "hash_small",
    alt: "hashtag",
    noSelectedValuesTx: "subscriptions.selectKeywords",
    allValuesSelectedTx: "subscriptions.allKeywordsSelected",
    fetched: false
  },
  documentCountries: {
    items: [],
    type: "documentCountries",
    nodeType: "DocumentCountry",
    icon: "pin_small",
    alt: "pin",
    noSelectedValuesTx: "subscriptions.selectCountries",
    allValuesSelectedTx: "subscriptions.allCountriesSelected",
    fetched: false
  },
  documentAuthors: {
    items: [],
    type: "documentAuthors",
    nodeType: "DocumentAuthor",
    icon: "user_small",
    alt: "authors",
    noSelectedValuesTx: "subscriptions.selectAuthors",
    allValuesSelectedTx: "subscriptions.allAuthorsSelected",
    fetched: false
  },
  documentContentTypes: {
    items: [],
    type: "documentContentTypes",
    nodeType: "DocumentContentType",
    icon: "contentType_small",
    alt: "contentTypes",
    noSelectedValuesTx: "subscriptions.selectContentTypes",
    allValuesSelectedTx: "subscriptions.allContentTypesSelected",
    fetched: false
  },
  documentPublishers: {
    items: [],
    type: "documentPublishers",
    nodeType: "DocumentPublisher",
    icon: "publisher_small",
    alt: "publishers",
    noSelectedValuesTx: "subscriptions.selectPublishers",
    allValuesSelectedTx: "subscriptions.allPublishersSelected",
    fetched: false
  },
  collections: {
    items: [],
    type: "collections",
    nodeType: "Collection",
    icon: "publisher_small",
    alt: "collections",
    noSelectedValuesTx: "subscriptions.selectCollections",
    allValuesSelectedTx: "subscriptions.allCollectionsSelected",
    fetched: false
  }
};
